
import { useCityRelTown, usefundCountriesDic } from "@/network/chinaArea";
import FundSignProjectTable from "./fundSignProjectTable";
import roles from "@/types/roles.ts";
import Pager from "@/views/layout/pager.vue";
import { cityDic } from "../projectDo/statusData.js";
import Base from "@/views/layout/Base.vue";
import FileSignManage from "./fundSignManage.vue";
import FileSignFileManage from "./fundSignFileManage.vue";
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
  toRef,
  inject,
} from "vue";
import { useQuery, useFundSign } from "@/network/fundSign";
export default defineComponent({
  components: {
    Base,
    FileSignManage,
    FileSignFileManage,
    Pager,
    FundSignProjectTable,
  },
  setup() {
    const projects = reactive({});
    const user: any = inject("user");
    const fileManageSetting = reactive({
      id: null,
      visible: false,
    });
    const manageSetting = reactive({
      id: null,
      visible: false,
    });

    const expandChange = (row) => {
      if (!projects[row.id]) {
        projects[row.id] = [];
        findProjects(row.applyUnitId, row.batchId).then((res) => {
          Object.assign(projects[row.id], res);
        });
      }
    };
    const showEdit = (item) => {
      fileManageSetting.visible = true;
      fileManageSetting.id = item.id;
    };

    const filter = reactive({
      isFundCountry: false,
      year: null,
      city: null,
      town: null,
      keyword: null,
      pageNum: 1,
      pageSize: 20,
    });

    const add = () => {
      manageSetting.id = null;
      manageSetting.visible = true;
    };

    const yearDic = [];

    let startYear = new Date().getFullYear() + 1;
    for (let i = 0; i <= 10; i++) {
      yearDic.push(startYear--);
    }
    const [dicCity, dicTown] = useCityRelTown(
      toRef(filter, "city"),
      toRef(filter, "town")
    );

    const [isLoading, list, query] = useQuery(filter);
    const [, , , , , findProjects] = useFundSign();
    const pager = reactive({
      count: toRef(list, "count"),
      pageSize: toRef(filter, "pageSize"),
      pageNum: toRef(filter, "pageNum"),
    });

    onMounted(() => {
      query();
    });

    return {
      yearDic,
      dicCity,
      expandChange,
      projects,
      add,
      manageSetting,
      fileManageSetting,
      cityDic,
      showEdit,
      isLoading,
      filter,
      pager,
      list,
      query,
      user,
      roles,
      fundCountries: usefundCountriesDic(),
    };
  },
});
