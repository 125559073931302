<template>
  <el-upload ref="refUpload" :limit="limit" :on-preview="onPreview" :on-exceed="onExceed"
    :on-remove="(f, l) => onRemoveFile(f, l)" :on-progress="onProgress" :action="action" multiple
    :on-success="onSuccess({})" :accept="accpet" :file-list="fileList">
    <slot>
      <el-button type="primary">{{ btnText }}</el-button>
    </slot>

    <template #tip>
      <div class="el-upload__tip" v-if="showTip">请上传{{ accpet }}文件</div>
    </template>
  </el-upload>
  <!-- <el-upload
    action="."
    :file-list="[{ name: 'test', url: 'xxx' }]"
    :on-remove="
      () => {
        alert(111);
      }
    "
  >
    <el-button type="primary">xxx</el-button>
  </el-upload>-->
</template>

<script lang="ts">
import { getToken } from "@/utils/auth";
import { strMBFormat, previewOrDownload } from "@/utils/util";
import settings from "@/settings";
import { ElMessage } from "element-plus";
import { defineComponent, toRefs, ref, reactive, watch } from "vue";

export default defineComponent({
  props: {
    setting: { type: Object },
  },
  emits: ["remove", "upload", "progress"],
  setup(props, { emit }) {
    const refUpload = ref(null);
    const {
      limit,
      accpet = ref(settings.attachment.accept),
      fileList = ref([]),
      showTip = ref(true),
      simple = ref(false),
      btnText = ref("点击上传"),
    } = toRefs(props.setting);

    watch(fileList, (v) => {
      if (v.length == 0) {
        fileList.value.length = 0;
        refUpload.value.clearFiles();
      }
      console.log(v)
    }, { deep: true });

    const onExceed = () => {
      if (!simple.value) {
        ElMessage.error(
          `仅允许上传${limit.value}份，请在附件下点击 x 移除现有文件后，重新上传！`
        );
      } else {
        ElMessage.error(`同时只能上传一份文件，请等待文件上传完毕！`);
      }
    };
    const onRemoveFile = (file, fileList) => {
      emit("remove", file);
    };
    const onSuccess = (ext) => {
      return (response, file, fileList) => {
        if (response.code == 200) {
          file.url = response.data;
          emit("upload", { url: response.data, name: file.name });
          if (simple.value) {
            refUpload.value.clearFiles();
            if (fileList.value) {
              fileList.value.length = 0;
            }
          }
        }
      };
    };
    const onProgress = () => {
      emit("progress");
    };
    const onPreview = (file) => {
      //&access_token=${getToken()}
      const url =
        file.url.indexOf("api/") > -1
          ? file.url
          : `${process.env.VUE_APP_BASE_API}/infrastructure/getFile?path=${file.url
          }&name=${encodeURIComponent(file.name)}`;

      window.open(url);
    };
    return {
      btnText,
      simple,
      action: settings.uploadAction,
      showTip,
      refUpload,
      limit,
      accpet,
      onExceed,
      onRemoveFile,
      onPreview,
      onSuccess,
      fileList,
      onProgress,
    };
  },
});
</script>

<style></style>
