
import fileupload from "@/components/fileupload";
import { useFundSign } from "@/network/fundSign";
import {
  inject,
  defineComponent,
  toRefs,
  ref,
  reactive,
  watch,
  Ref,
  PropType,
  computed,
  toRef,
  onMounted,
  nextTick,
} from "vue";
import settings from "@/settings";
export default defineComponent({
  components: { fileupload },
  props: {
    setting: { type: Object },
  },
  emits: ["change"],
  setup(props, { emit }) {
    const user: any = inject("user");
    const { visible, id } = toRefs(props.setting as any);
    const [isLoading, , , findFiles, saveFiles] = useFundSign();
    const form = reactive({
      id: 0,
      applys: [],
      allocates: [],
      baks: [],
      performances: [],
    });
    const refForm = ref(null);

    const onRemoveAttach = (res, list) => {
      const index = list.findIndex((p) => p.url == res.url);
      if (index > -1) list.splice(index, 1);
      //console.log(index, list);
    };
    const onUploadAttach = (res, list) => {
      list.push(res);
      //console.log(list);
    };
    // watch(visible, (v) => {
    //   if (v) {
    //     findFiles(id.value).then((p) => {
    //       form.id = p.id;
    //       Object.assign(form.applys, p.applys);
    //       Object.assign(form.allocates, p.allocates);
    //       Object.assign(form.baks, p.baks);
    //       Object.assign(form.performances, p.performances);
    //       //Object.assign(form, p);
    //       //form.applys.push({url:'test',name:'test'});
    //       //console.log(form);
    //       nextTick(() => {
    //         setTimeout(() => refForm.value.clearValidate(), 1);
    //       })

    //     });
    //   }
    // });

    onMounted(() => {
      findFiles(id.value).then((p) => {
        form.id = p.id;
        Object.assign(form.applys, p.applys);
        Object.assign(form.allocates, p.allocates);
        Object.assign(form.baks, p.baks);
        Object.assign(form.performances, p.performances);
        //Object.assign(form, p);
        //form.applys.push({url:'test',name:'test'});
        //console.log(form);
        nextTick(() => {
          setTimeout(() => refForm.value.clearValidate(), 1);
        })

      });
    })
    const doSave = () => {
      return new Promise((resolve, reject) => {
        refForm.value.validate((valid) => {
          if (valid) {
            saveFiles(id.value, form).then(() => {
              resolve("success");
              visible.value = false;
              emit("change", form);
            });
          } else {
            reject("error");
          }
        });
      });
    };

    return {
      user,
      refForm,
      isLoading,
      visible,
      form,
      doSave,
      rules: {},
      onRemoveAttach,
      onUploadAttach,
    };
  },
});
