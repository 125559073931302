<template>
  <Base titleIcon="el-icon-s-claim">
    <el-space wrap style="margin-bottom: 12px">
      <el-select collapse-tags v-model="filter.year" placeholder="资金年份">
        <el-option :label="i" :value="i" v-for="i in yearDic" :key="i">
        </el-option>
      </el-select>

      <el-switch
        v-model="filter.isFundCountry"
        active-text="直管县"
        inactive-text="市级"
        v-if="user.inRoles(roles.prov)"
        @change="
          filter.city = filter.town = null;
          query({ pageNum: 1 });
        "
      />

      <el-select
        v-model="filter.town"
        placeholder="直管县"
        clearable
        v-if="user.inRoles(roles.prov) && filter.isFundCountry"
      >
        <el-option
          :value="item.id"
          :label="item.name"
          :key="item.id"
          v-for="item in fundCountries"
        ></el-option>
      </el-select>

      <el-select
        v-model="filter.city"
        placeholder="市级"
        clearable
        v-show="user.inRoles(roles.prov)"
        v-if="user.inRoles(roles.prov) && !filter.isFundCountry"
      >
        <el-option
          v-for="item in dicCity"
          :key="item.id"
          :value="item.id"
          :label="item.name"
        ></el-option>
      </el-select>

      <el-input
        placeholder="发文文号/发文机构"
        v-model="filter.keyword"
        style="width: 500px"
        @keyup.enter="query({ pageNum: 1 })"
      >
        <template #prepend> 关键字 </template>
      </el-input>
      <el-button
        type="primary"
        style="margin-left: 5px"
        @click="query({ pageNum: 1 })"
        >查询</el-button
      >

      <el-button
        v-if="!user.inRoles(roles.prov) && user.inRoles(roles.review)"
        type="primary"
        icon="el-icon-plus"
        style="margin-left: 25px"
        @click="add"
        >添加申请</el-button
      >
    </el-space>
    <FileSignManage
      :setting="manageSetting"
      @change="query({ pageNum: 1 })"
      v-if="manageSetting.visible"
    ></FileSignManage>

    <FileSignFileManage
      :setting="fileManageSetting"
      @change="query({ pageNum: 1 })"
      v-if="fileManageSetting.visible"
    ></FileSignFileManage>
    <el-table
      :data="list.list"
      v-loading="isLoading"
      @expand-change="expandChange"
    >
      <el-table-column type="expand">
        <template #default="{ row: item }">
          <FundSignProjectTable
            :projects="projects[item.id]"
          ></FundSignProjectTable>
        </template>
      </el-table-column>
      <el-table-column label="资金年份" prop="year"></el-table-column>
      <el-table-column label="市级/直管县" prop="areaName"> </el-table-column>
      <el-table-column label="申报文件" prop="申报文件">
        <template #default="{ row: item }">
          <div>
            <el-link
              :href="i.url"
              v-for="i in item.applys"
              :key="i.id"
              target="_blank"
              >{{ i.name }}</el-link
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column label="安排文件" prop="安排文件">
        <template #default="{ row: item }">
          <div>
            <el-link :href="i.url" v-for="i in item.allocates" :key="i.id">{{
              i.name
            }}</el-link>
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column label="备案文件" prop="备案文件">
        <template #default="{ row: item }">
          <div>
            <el-link :href="i.url" v-for="i in item.baks" :key="i.id">{{
              i.name
            }}</el-link>
          </div>
        </template></el-table-column
      > -->

      <el-table-column
        label="操作"
        v-if="user.inRoles(roles.prov) || user.inRoles(roles.review)"
      >
        <template #default="{ row: item }">
          <el-button type="text" icon="el-icon-edit" @click="showEdit(item)"
            >{{ item.batchId?.split('-')[0] }}上传文件</el-button
          >
        </template>
      </el-table-column>

      <!-- <el-table-column label="操作">
        <template #default>
          <el-button type="primary" icon="el-icon-check" size="mini"
            >审核</el-button
          >
        </template>
      </el-table-column> -->
    </el-table>
    <Pager :pager="pager" @change="query()"></Pager>
  </Base>
</template>

<script lang="ts">
import { useCityRelTown, usefundCountriesDic } from "@/network/chinaArea";
import FundSignProjectTable from "./fundSignProjectTable";
import roles from "@/types/roles.ts";
import Pager from "@/views/layout/pager.vue";
import { cityDic } from "../projectDo/statusData.js";
import Base from "@/views/layout/Base.vue";
import FileSignManage from "./fundSignManage.vue";
import FileSignFileManage from "./fundSignFileManage.vue";
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
  toRef,
  inject,
} from "vue";
import { useQuery, useFundSign } from "@/network/fundSign";
export default defineComponent({
  components: {
    Base,
    FileSignManage,
    FileSignFileManage,
    Pager,
    FundSignProjectTable,
  },
  setup() {
    const projects = reactive({});
    const user: any = inject("user");
    const fileManageSetting = reactive({
      id: null,
      visible: false,
    });
    const manageSetting = reactive({
      id: null,
      visible: false,
    });

    const expandChange = (row) => {
      if (!projects[row.id]) {
        projects[row.id] = [];
        findProjects(row.applyUnitId, row.batchId).then((res) => {
          Object.assign(projects[row.id], res);
        });
      }
    };
    const showEdit = (item) => {
      fileManageSetting.visible = true;
      fileManageSetting.id = item.id;
    };

    const filter = reactive({
      isFundCountry: false,
      year: null,
      city: null,
      town: null,
      keyword: null,
      pageNum: 1,
      pageSize: 20,
    });

    const add = () => {
      manageSetting.id = null;
      manageSetting.visible = true;
    };

    const yearDic = [];

    let startYear = new Date().getFullYear() + 1;
    for (let i = 0; i <= 10; i++) {
      yearDic.push(startYear--);
    }
    const [dicCity, dicTown] = useCityRelTown(
      toRef(filter, "city"),
      toRef(filter, "town")
    );

    const [isLoading, list, query] = useQuery(filter);
    const [, , , , , findProjects] = useFundSign();
    const pager = reactive({
      count: toRef(list, "count"),
      pageSize: toRef(filter, "pageSize"),
      pageNum: toRef(filter, "pageNum"),
    });

    onMounted(() => {
      query();
    });

    return {
      yearDic,
      dicCity,
      expandChange,
      projects,
      add,
      manageSetting,
      fileManageSetting,
      cityDic,
      showEdit,
      isLoading,
      filter,
      pager,
      list,
      query,
      user,
      roles,
      fundCountries: usefundCountriesDic(),
    };
  },
});
</script>

<style scoped></style>
