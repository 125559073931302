
import FundSignProjectTable from "./fundSignProjectTable";
import { useFundSign } from "@/network/fundSign";
import {
  inject,
  defineComponent,
  toRefs,
  ref,
  reactive,
  watch,
  Ref,
  PropType,
  computed,
  toRef,
  onMounted,
  nextTick
} from "vue";
import { copyFrom } from "@/network/common";

export default defineComponent({
  components: {
    FundSignProjectTable,
  },
  props: {
    setting: { type: Object },
  },
  emits: ["change"],
  setup(props, { emit }) {
    const user: any = inject("user");
    const { visible, id } = toRefs(props.setting as any);
    const [isLoading, createInit, save] = useFundSign();
    const empty = {
      id: 0,
      batchId: null,
      code: null,
      publishUnit: null,
      projects: [],
    };
    const form = reactive(copyFrom(empty));
    const refForm = ref(null);
    const projects = [];
    // watch(visible, (v) => {
    //   if (v) {
    //     createInit().then((p) => {
    //       Object.assign(form, empty, p);
    //       nextTick(() => {
    //         setTimeout(() => refForm.value.clearValidate(), 1);
    //       })
    //     });
    //   }
    // });

    onMounted(() => {
      createInit().then((p) => {
        Object.assign(form, empty, p);
        nextTick(() => {
          setTimeout(() => refForm.value.clearValidate(), 1);
        })
      });
    })

    const doSave = () => {
      return new Promise((resolve, reject) => {
        refForm.value.validate((valid) => {
          if (valid) {
            save(form).then(() => {
              resolve("success");
              visible.value = false;
              emit("change", form);
            });
          } else {
            reject("error");
          }
        });
      });
    };

    return {
      projects,
      user,
      refForm,
      isLoading,
      visible,
      form,
      doSave,
      rules: {
        code: [
          {
            //required: true,
            message: "请填写年度!",
            trigger: "blur",
          },
        ],
        publishUnit: [
          {
            // required: true,
            message: "请填写批次!",
            trigger: "blur",
          },
        ],
      },
    };
  },
});
