<template>
  <el-dialog v-model="visible" title="资金文件管理" width="80%">
    <el-card v-loading="isLoading">
      <div class="borderForm">
        <el-form :model="form" label-width="200px" ref="refForm" :rules="rules">
          <el-card>
            <el-form-item label="申报文件" prop="applys">
              <fileupload :setting="{
                accpet: '.Doc,.Docx,.Pdf',
                fileList: form.applys,
              }" @remove="(res) => onRemoveAttach(res, form.applys)"
                @upload="(res) => onUploadAttach(res, form.applys)"></fileupload>
            </el-form-item>
          </el-card>
          <el-card>
            <el-form-item label="安排文件" prop="allocates">
              <fileupload :setting="{
                accpet: '.Doc,.Docx,.Pdf',
                fileList: form.allocates,
              }" @remove="(res) => onRemoveAttach(res, form.allocates)"
                @upload="(res) => onUploadAttach(res, form.allocates)"></fileupload>
            </el-form-item>
          </el-card>
          <!-- <el-card>
            <el-form-item label="备案文件" prop="baks">
              <fileupload
                :setting="{
                  accpet: '.Doc,.Docx,.Pdf',
                  fileList: form.baks,
                }"
                @remove="(res) => onRemoveAttach(res, form.baks)"
                @upload="(res) => onUploadAttach(res, form.baks)"
              ></fileupload
            ></el-form-item>
          </el-card> -->
          <el-card>
            <el-form-item label="绩效自评文件" prop="performances">
              <fileupload :setting="{
                accpet: '.Doc,.Docx,.Pdf',
                fileList: form.performances,
              }" @remove="(res) => onRemoveAttach(res, form.performances)"
                @upload="(res) => onUploadAttach(res, form.performances)"></fileupload>
            </el-form-item>
          </el-card>
        </el-form>
      </div>
    </el-card>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="visible = false">关闭</el-button>
        <el-popconfirm title="确认保存吗！？" @confirm="doSave()" placement="top">
          <template #reference>
            <el-button type="primary" :loading="isLoading" icon="el-icon-check">
              {{ isLoading ? "处理中" : "保 存" }}
            </el-button>
          </template>
        </el-popconfirm>
      </span>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import fileupload from "@/components/fileupload";
import { useFundSign } from "@/network/fundSign";
import {
  inject,
  defineComponent,
  toRefs,
  ref,
  reactive,
  watch,
  Ref,
  PropType,
  computed,
  toRef,
  onMounted,
  nextTick,
} from "vue";
import settings from "@/settings";
export default defineComponent({
  components: { fileupload },
  props: {
    setting: { type: Object },
  },
  emits: ["change"],
  setup(props, { emit }) {
    const user: any = inject("user");
    const { visible, id } = toRefs(props.setting as any);
    const [isLoading, , , findFiles, saveFiles] = useFundSign();
    const form = reactive({
      id: 0,
      applys: [],
      allocates: [],
      baks: [],
      performances: [],
    });
    const refForm = ref(null);

    const onRemoveAttach = (res, list) => {
      const index = list.findIndex((p) => p.url == res.url);
      if (index > -1) list.splice(index, 1);
      //console.log(index, list);
    };
    const onUploadAttach = (res, list) => {
      list.push(res);
      //console.log(list);
    };
    // watch(visible, (v) => {
    //   if (v) {
    //     findFiles(id.value).then((p) => {
    //       form.id = p.id;
    //       Object.assign(form.applys, p.applys);
    //       Object.assign(form.allocates, p.allocates);
    //       Object.assign(form.baks, p.baks);
    //       Object.assign(form.performances, p.performances);
    //       //Object.assign(form, p);
    //       //form.applys.push({url:'test',name:'test'});
    //       //console.log(form);
    //       nextTick(() => {
    //         setTimeout(() => refForm.value.clearValidate(), 1);
    //       })

    //     });
    //   }
    // });

    onMounted(() => {
      findFiles(id.value).then((p) => {
        form.id = p.id;
        Object.assign(form.applys, p.applys);
        Object.assign(form.allocates, p.allocates);
        Object.assign(form.baks, p.baks);
        Object.assign(form.performances, p.performances);
        //Object.assign(form, p);
        //form.applys.push({url:'test',name:'test'});
        //console.log(form);
        nextTick(() => {
          setTimeout(() => refForm.value.clearValidate(), 1);
        })

      });
    })
    const doSave = () => {
      return new Promise((resolve, reject) => {
        refForm.value.validate((valid) => {
          if (valid) {
            saveFiles(id.value, form).then(() => {
              resolve("success");
              visible.value = false;
              emit("change", form);
            });
          } else {
            reject("error");
          }
        });
      });
    };

    return {
      user,
      refForm,
      isLoading,
      visible,
      form,
      doSave,
      rules: {},
      onRemoveAttach,
      onUploadAttach,
    };
  },
});
</script>

<style scoped></style>