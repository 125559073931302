
import { DocComponents } from "@/network/officialDoc.ts";
import { defineComponent, reactive, ref, toRefs, Ref } from "vue";

export default defineComponent({
  components: {
    ...DocComponents,
  },
  props: {
    projects: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const docShowSetting = reactive({
      id: null,
      visible: false,
      approve: false,
    });
    const showDocContent = (id) => {
      docShowSetting.id = id;
      docShowSetting.visible = true;
    };
    return {
      showDocContent,
      docShowSetting,
    };
  },
});
