import request from "@/utils/request";
import { reactive, onMounted, ref, Ref } from "vue";
import { ElMessage } from "element-plus";
import axios from "axios";

export function useQuery(filter): Array<any> {
  const isLoading = ref(false);
  const data = reactive({
    list: [],
    count: 0,
  });

  function query(replaceFilter = null) {
    Object.assign(filter, replaceFilter ?? {});
    isLoading.value = true;
    request
      .get(`/fundSign/query`, {
        params: filter,
      })
      .then((res) => {
        isLoading.value = false;
        data.list = res.list;
        data.count = res.count;
      });
  }

  return [isLoading, data, query];
}

export function useFundSign(): Array<any> {
  const isLoading = ref(false);
  const cancel = ref(null);

  function createInit() {
    isLoading.value = true;
    const cancelTokenSource = axios.CancelToken.source();
    cancel.value = cancelTokenSource;
    return request
      .get(`/fundSign/createInit`)
      .then((res) => {
        isLoading.value = false;
        return res;
      })
      .catch(() => {
        isLoading.value = false;
      });
  }
  function findFiles(idValue) {
    isLoading.value = true;
    const cancelTokenSource = axios.CancelToken.source();
    cancel.value = cancelTokenSource;
    return request
      .get(`/fundSign/findFiles/${idValue}`)
      .then((res) => {
        isLoading.value = false;
        return res;
      })
      .catch(() => {
        isLoading.value = false;
      });
  }

  function save(result) {
    isLoading.value = true;
    let ps = null;
    const cancelTokenSource = axios.CancelToken.source();
    cancel.value = cancelTokenSource;
    ps = request
      .post(`/fundSign`, result)
      .then((res) => {
        isLoading.value = false;
        ElMessage.success({ message: "数据已保存", type: "success" });
      })
      .catch((e) => {
        isLoading.value = false;
        throw e;
      });

    return ps;
  }

  function saveFiles(id, result) {
    isLoading.value = true;
    let ps = null;
    const cancelTokenSource = axios.CancelToken.source();
    cancel.value = cancelTokenSource;
    ps = request
      .put(`/fundSign/saveFiles/${id}`, result)
      .then((res) => {
        isLoading.value = false;
        ElMessage.success({ message: "数据已保存", type: "success" });
      })
      .catch((e) => {
        isLoading.value = false;
        throw e;
      });

    return ps;
  }

  function findProjects(userId, batchId) {
    isLoading.value = true;
    let ps = null;
    const cancelTokenSource = axios.CancelToken.source();
    cancel.value = cancelTokenSource;
    ps = request
      .get(`/fundSign/findProjects/${userId}/${batchId}`)
      .then((res) => {
        isLoading.value = false;
        return res;
      })
      .catch((e) => {
        isLoading.value = false;
        throw e;
      });

    return ps;
  }
  return [isLoading, createInit, save, findFiles, saveFiles, findProjects];
}

// export const useAction = () => {
//   const isLoading = ref(false);
//   const cancel = ref(null);
//   const toggle = (id) => {
//     return request
//       .put(`/fundBatch/toggleStatus/${id}`)
//       .then((res) => {
//         isLoading.value = false;
//         ElMessage.success({ message: "操作成功！", type: "success" });
//         return res;
//       })
//       .catch(() => {
//         isLoading.value = false;
//       });
//   };

//   function remove(idValue: number): void {
//     isLoading.value = true;
//     const cancelTokenSource = axios.CancelToken.source();
//     cancel.value = cancelTokenSource;
//     return request
//       .delete(`/fundBatch/${idValue}`)
//       .then((res) => {
//         isLoading.value = false;
//         ElMessage.success({ message: "操作成功！", type: "success" });
//         return res;
//       })
//       .catch(() => {
//         isLoading.value = false;
//       });
//   }

//   return [isLoading, toggle, remove];
// };
