<template>
  <DocContent :setting="docShowSetting" v-if="docShowSetting.visible"></DocContent>
  <el-table :data="projects" max-height="600">
    <el-table-column label="市级/直管县" prop="areaName"></el-table-column>
    <el-table-column label="项目编号" prop="project_Code"></el-table-column>
    <el-table-column label="项目名称" prop="project_Name"></el-table-column>
    <el-table-column
      label="文保单位"
      prop="heritageUnit_Name"
    ></el-table-column>
    <el-table-column label="批文">
      <template #default="{ row: item }">
        <template v-if="item.setupDoc_Id != null">
          计划书：<DocViewBtn
            :doc="{
              id: item.setupDoc_Id,
              doc_Compile_No: item.setupDoc_Name,
            }"
            @show="showDocContent(item.setupDoc_Id)"
          ></DocViewBtn>
        </template>
        <template v-if="item.proposalDoc_Id != null">
          方案：<DocViewBtn
            :doc="{
              id: item.proposalDoc_Id,
              doc_Compile_No: item.proposalDoc_Name,
            }"
            @show="showDocContent(item.proposalDoc_Id)"
          ></DocViewBtn>
        </template>
        <template v-else>
          <i class="el-icon-warning Red"></i> {{ item.noDoc_Note }}
        </template>
      </template>
    </el-table-column>
    <el-table-column
      label="预算控制数(万元)"
      prop="assessment_Money"
    ></el-table-column>
    <el-table-column
      label="累计安排(万元)"
      prop="totalGive_Money"
    ></el-table-column>
    <el-table-column
      label="本次申请金额(万元)"
      prop="applyAmount"
    ></el-table-column>
    <el-table-column label="申报单位" prop="new_User_Name"></el-table-column>
  </el-table>
</template>

<script lang="ts">
import { DocComponents } from "@/network/officialDoc.ts";
import { defineComponent, reactive, ref, toRefs, Ref } from "vue";

export default defineComponent({
  components: {
    ...DocComponents,
  },
  props: {
    projects: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const docShowSetting = reactive({
      id: null,
      visible: false,
      approve: false,
    });
    const showDocContent = (id) => {
      docShowSetting.id = id;
      docShowSetting.visible = true;
    };
    return {
      showDocContent,
      docShowSetting,
    };
  },
});
</script>

<style scoped>
</style>